import consumer from "./consumer"
import printJS from "print-js";
require("turbolinks").start();

consumer.subscriptions.create("PrintPdfChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    const saleId = data.saleId
    printJS({
      printable: location.href + `sales/${saleId}.pdf`,
      type: 'pdf',
      onPrintDialogClose: ()=> {
        toastr.success('Enregistré !')
        Turbolinks.visit(window.location.href)
      }
    })
    // Called when there's incoming data on the websocket for this channel
  }
});
